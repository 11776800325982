import * as React from "react"
import { authContext } from "./AuthContext"
import { AuthPageWrapper, ForgotPasswordWrapper } from "./index.styles"
import { Form, Input } from "semantic-ui-react"

import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { Auth } from "aws-amplify"
import { AuthFormLabel, AuthTitle } from "@styles/Global.forms"
import { Paragraph2 } from "@styles/Global.styles"
import ErrorMessage from "./ErrorMessage"
import { navigate } from "gatsby-link"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import { DealerSignInLink } from "@components/Dealer/OwnerDetailsForm.style"
import { Link } from "gatsby"
import { saveShowModalResetPassword } from "@redux/localStorage/checkout"
import { DealerService } from "@services/DealerService"

const ForgotPassword = (props: { apiUrl: string }) => {
  const { auth } = React.useContext(authContext)

  const [email, setEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [passwordDest, setPasswordDest] = React.useState()

  const [code, setCode] = React.useState("")
  const [disableReset, setDisableReset] = React.useState(true)
  const [password, setPassword] = React.useState("")
  const [passwordAgain, setPasswordAgain] = React.useState("")
  const [error, setError] = React.useState("")


  async function handleCheckEmail(email: string) {
    setEmail(email)
    setLoading(true)
    await DealerService.verifySalesPerson(props.apiUrl, email.trim())
    .then(response => {
      if(response.Status === 200){
        setDisableReset(false)
        setLoading(false)
      } else {
        setDisableReset(true)
        setLoading(false)
      }
    })
    .catch(exception => {
      setLoading(false)
      setDisableReset(true)
    })
  }

  const handleForgotPassword = (e: any) => {
    e.preventDefault()
    setLoading(true)
    MarketplaceListingService.checkEmailUsers(props.apiUrl, email)
      .then(response =>{
        if(response.Status == 200){
          if(response.Success){
            Auth.forgotPassword(email)
            .then(res => {
              setLoading(false)
              console.log(res)
              const dest = res.CodeDeliveryDetails.Destination
              setPasswordDest(dest)
              saveShowModalResetPassword('False')
            })
            .catch(exception => {
              console.log(exception)
              setLoading(false)
            })
          } else{
            setLoading(false)
            setError(response.Message)
          }
        }
      })
      .catch(exception => {
        console.log(exception)
        setLoading(false)
      })
  }

  const handleChangePassword = (e: any) => {
    e.preventDefault()
    setLoading(true)
    Auth.forgotPasswordSubmit(email, code, password)
      .then(res => {
        setLoading(false)
        navigate("/dealer-login")
      })
      .catch(exception => {
        setError(exception.message)
        setLoading(false)
      })
  }

  return (
    <AuthPageWrapper>
      {passwordDest ? (
        <ForgotPasswordWrapper>
          <Paragraph2 color={theme.brand.colors.darkGrey}>
            We have sent a password reset code by email to {passwordDest}. Enter
            it below to reset your password.
          </Paragraph2>
          <Form onSubmit={handleChangePassword}>
            <Form.Field>
              <AuthFormLabel>Code</AuthFormLabel>
              <Input
                type="text"
                name="code"
                value={code}
                id="code"
                onChange={e => setCode(e.target.value)}
                autoComplete="new-password"
              />
            </Form.Field>

            <Form.Field>
              <AuthFormLabel>New Password</AuthFormLabel>
              <Input
                type="password"
                name="password"
                value={password}
                id="newpassword"
                onChange={e => setPassword(e.target.value)}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <AuthFormLabel>Enter New Password Again</AuthFormLabel>
              <Input
                type="password"
                name="passwordAgain"
                value={passwordAgain}
                id="newpasswordagain"
                onChange={e => setPasswordAgain(e.target.value)}
                autoComplete="new-password"
              />
            </Form.Field>
            {error && <ErrorMessage errorMessage={error} />}
            <Form.Field
              style={{ textAlign: "center", padding: "10px 0 10px 0" }}
            >
              <KpButton
                id="changepassword-submit"
                color={theme.brand.colors.green}
                fullWidth="mobile tablet computer"
                buttonType={ButtonTypes.Primary}
                loading={loading}
                type="submit"
              >
                {loading ? "Loading..." : "Change Password"}
              </KpButton>
            </Form.Field>
          </Form>
        </ForgotPasswordWrapper>
      ) : (
        <ForgotPasswordWrapper>
          <AuthTitle>Forgot your password?</AuthTitle>
          <Paragraph2 color={theme.brand.colors.darkGrey}>
            Enter your Email below and we will send a message to reset your
            password
          </Paragraph2>
          <Form onSubmit={handleForgotPassword}>
            <Form.Field>
              <AuthFormLabel>EMAIL</AuthFormLabel>
              <Input
                type="email"
                name="email"
                value={email}
                id="email"
                placeholder="yourname@email.com"
                onChange={e => {handleCheckEmail(e.target.value); setError("")}}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field
              style={{ textAlign: "center", padding: "10px 0 10px 0" }}
            >
              <KpButton
                id="signin-submit"
                color={theme.brand.colors.green}
                fullWidth="mobile tablet computer"
                buttonType={ButtonTypes.Primary}
                loading={loading}
                disabled={disableReset}
                type="submit"
              >
                {loading ? "Loading..." : "Reset my password"}
              </KpButton>
            </Form.Field>
            {error && <ErrorMessage errorMessage={error} />}
          </Form>
          <DealerSignInLink>
              <Link to="/dealer-login">Back To Login</Link>
          </DealerSignInLink>
        </ForgotPasswordWrapper>
      )}
    </AuthPageWrapper>
  )
}
export default ForgotPassword
