import React from "react"
import { Layout } from "@components/Layout"
import ForgotPassword from "@dealer-auth/ForgotPassword"

import { SEO } from "@components/SEO"
import { graphql, useStaticQuery } from "gatsby"

const DealerForgotPasswordPage = () => {
  const data = useStaticQuery(
    graphql`{
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
    `,
  );
  const apiUrl = data.site.siteMetadata.apiUrl
  return (
    <Layout version="dealer">
      <SEO title="Forgot Password" />
      <ForgotPassword apiUrl={apiUrl}/>
    </Layout>
  )
  
}

export default DealerForgotPasswordPage
